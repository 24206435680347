@font-face {
	font-family: 'cormorantgaramond-med';
	src: url('fonts/cormorantgaramond-medium.woff2') format('woff2'),
		 url('fonts/cormorantgaramond-medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'eb_garamondregular';
	src: url('fonts/ebgaramond-variablefont_wght.woff2') format('woff2'),
		 url('fonts/ebgaramond-variablefont_wght.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}


body {
	font-family: 'eb_garamondregular';
	font-size: 20px;
	margin-bottom: 60px;
}

.App {
	margin-bottom: 30px;
}

h1 {
	margin: 0.75rem 0.5rem 0.5rem;
	font-family: 'cormorantgaramond-med';
	font-size: 200%;
	text-align: center;
}

#Question {
	margin: 20px 2rem 1rem;
}


