
#Message {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	height: 1.25em;
	margin: -1em auto;
	padding: 0.35em 0.75em;
	max-width: 12.5em;
	text-align: center;
	z-index: -1;
	opacity: 0;
	border: 3px solid;
	border-radius: 7px;
}

#PickList.c0 #Message {
	top: -310px;
}

#Message.correct {
	z-index: 100;
	border-color: #060;
	color: #008000;
	background-color: #efe;
}

#Message.wrong {
	z-index: 100;
	border-color: #600;
	color: #c00;
	background-color: #fee;
}
#Message.invalid {
	z-index: 100;
	border-color: #666;
	color: #555;
	background-color: #f0f0f0;
}
#Message.hide {
	transition: opacity 1250ms;
	opacity: 0;
}
#Message.show {
	transition: opacity 0ms;
	opacity: 1;
}

