
#toggle-container {
	text-align: center;
}
#toggle-container button {
	font-family: 'eb_garamondregular';
	font-size: 125%;
	padding: 0.25em 0.75em;
	border-radius: 7px;
}

#ModeSelector > div {
	margin: auto 15px;
}
#ModeSelector fieldset {
	max-width: 23em;
	position: relative;
	margin: 2em auto 1em;
	border-top: 2px solid #777;
	border-left: 2px solid #777;
	border-right: 2px solid #ccc;
	border-bottom: 2px solid #ccc;
	border: none;
	background: linear-gradient(160deg, #efe, #fff);
	border-top: 1px solid #7c7;
	/*border-left: 1px solid #7c7;*/
}


#ModeSelector {
	overflow: hidden;
	height: auto;
	transition: max-height 0.5s
}
.App.quiz #ModeSelector {
	max-height: 60px;
}
.App.study #ModeSelector {
	max-height: 600px;
}


@media (max-width: 372px) {
	#ModeSelector fieldset {
		font-size: 95%;
	}
}
#ModeSelector legend {
	position: relative;
	top: -0.65em;
	border-bottom: 1px solid #7c7;
}
@supports (-ms-ime-align:auto) {
	#ModeSelector legend {
		top: -1.3em;
	}
}

#ModeSelector fieldset .check-box-container {
	clear: left;
}

#ModeSelector .check-box-container label {
	display: block;
	padding-left: 1.4em;
	text-indent: -1.4em;
}
