#help-link-wrapper {
	display: block;
	margin: 1em auto 1em;
	text-align: center;
}

#help-content {
	display: none;
	position: fixed;
	top: 30px;
	left: 20px;
	right: 20px;
	bottom: 50px;
	border: 2px solid #aaa;
	box-shadow: 6px 6px 8px #040;
	padding: 7px 20px;
	color: #040;
	background: linear-gradient(180deg, #f2fff2, #fff);
	z-index: 100;
	overflow: auto;
}
#help-content.show {
	display: block;
}

#help-content ul {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
}
#help-content li {
	padding-left: 1.25em;
	text-indent: -1.25em;
}
#help-content li:before {
	content: "☘";
	padding-right: 0.25em;
}

#help-content p:last-child {
	text-align: center;
}

#HelpModule button {
	border: none;
	background: none;
	color: #068533;
	text-decoration: underline;
	font: 110% 'eb_garamondregular';
	cursor: pointer;
}
