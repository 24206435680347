
#PickList {
	margin-bottom: 2rem;
	margin-left: 2rem;
	outline: none;
	position: relative;
	counter-reset: list;
}

#PickList li {
	width: 10em;
	margin-top: 1em;
	margin-bottom: 1em;
	color: #262;
	counter-increment: list;
}
#PickList li::marker {
	content: counter(list) ")";
}
#PickList li a {
	text-decoration: none;
	color: #262;
	padding: 0.5em;
	border-radius: 7px;
	outline: none;
}
#PickList li a:hover,
#PickList li a:focus {
	background-color: #c9ffc9;
}
#PickList li a.wrong {
	text-decoration: line-through;
	color: #777;
}
#PickList li a.wrong:hover,
#PickList li a.wrong:focus {
	background-color: #fff;
	cursor: default;
}

#PickList.c0 {
	height: 0;
}

